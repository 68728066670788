/*
 * values of model User Auth
 * */

import { IsNotEmpty, MaxLength } from 'class-validator';

export default class UserAuth {
  @IsNotEmpty()
  @MaxLength(128)
  login!: string;

  @IsNotEmpty()
  @MaxLength(128)
  password!: string;
}
